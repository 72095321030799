import cockpitClient from '@/App/http/cockpit-http-common'

class AlertsService {
  getAlertActions(): Promise<any> {
    return cockpitClient.get('/AlertActions')
    .catch((ex:Response)=>{
      console.log(ex.status)
    })
  }
  getAlertIndicators(): Promise<any> {
    return cockpitClient.get('/AlertIndicators')
  }

  getAlertIndicatorsSettings(): Promise<any> {
    return cockpitClient.get('/AlertIndicatorsSettings')
  }
}
export default new AlertsService()
